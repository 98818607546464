import React from 'react';

const clients = [
  { name: 'QNCC logo', logo: '/QNCC logo.png'},
  { name: 'visit-qatar-logo', logo: '/visit-qatar-logo.png' },
  { name: 'studio5Logo', logo: '/studio5Logo.png' },
  { name: 'Katara Art Center logo', logo: '/Katara Art Center logo.png'},
  { name: 'qstp-logo', logo: '/qstp.png' },
  { name: 'riwaq-logo', logo: '/riwaq logo.png' },
  { name: 'QNCC logo', logo: '/QNCC logo.png'},
  { name: 'visit-qatar-logo', logo: '/visit-qatar-logo.png' },
  { name: 'studio5Logo', logo: '/studio5Logo.png' },
  { name: 'Katara Art Center logo', logo: '/Katara Art Center logo.png'},
  { name: 'qstp-logo', logo: '/qstp.png' },
  { name: 'riwaq-logo', logo: '/riwaq logo.png' },
  // { name: 'qm-logo', logo: '/qm logo.png' },
  // { name: 'gensmart-logo', logo: '/gensmart logo.png' },
];


const ClientsSlider = () => {
  return (
    <section id="clients" className="pb-20 relative overflow-hidden">
      <div className="w-full bg-white py-10">
        {/* Logo Grid Wrapper */}
        <div className="grid grid-cols-2 sm:grid-cols-3 gap-8">
          {clients.map((client, index) => (
            <div key={index} className="flex items-center justify-center">
              <div className="relative h-16 w-auto flex items-center justify-center">
                <img
                  src={`/projects logos/${client.logo}`}
                  alt={client.name}
                  className="h-full w-auto object-contain opacity-70 hover:opacity-100 duration-700 transition-opacity"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

const ClientsSlider1 = () => {
  return (
    <section id="clients" className=" pb-20 relative overflow-hidden">
      <div className="w-full overflow-hidden bg-black py-10">
        {/* Logo Slider Wrapper */}
        <div className="logo-slider relative">
          <div className="logo-slide-track flex animate-slide-left">
            {/* Original logos */}
            {clients.map((client, index) => (
              <div key={index} className="logo-slide flex-shrink-0 mx-10">
                <div className="relative h-24 w-48 flex items-center justify-center">
                  <img
                    src={`/projects logos/${client.logo}`}
                    alt={client.name}
                    className="h-full object-contain opacity-70 hover:opacity-100 duration-700 transition-opacity filter grayscale"
                  />
                </div>
              </div>
            ))}
            {/* Duplicate logos to ensure smooth looping */}
            {clients.slice(0, 8).map((client, index) => (
              <div key={`duplicate-${index}`} className="logo-slide flex-shrink-0 mx-10">
                <div className="relative h-24 w-48 flex items-center justify-center">
                  <img
                    src={`/projects logos/${client.logo}`}
                    alt={client.name}
                    className="h-full object-contain opacity-70 hover:opacity-100 duration-700 transition-opacity filter grayscale"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ClientsSlider1;