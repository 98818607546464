import React from 'react';
import { Button } from "@/components/ui/button";
import { ArrowRight } from "lucide-react";

const BookDemoButton = ({type="", size=null}) => {
  const handleBookDemo = () => {
    let bookingLink = 'https://calendly.com/juliano-monet'; // Default link
    // Set the booking link based on the type
    switch (type) {
      case 'realestate':
        bookingLink = 'https://calendly.com/juliano-monet/digital-twin-real-estate';
        break;
      case 'interior':
        bookingLink = 'https://calendly.com/juliano-monet/digital-twin-interior-design';
        break;
      case 'art':
        bookingLink = 'https://calendly.com/juliano-monet/digital-twin-art-culture';
        break;
      case 'warehouse':
        bookingLink = 'https://calendly.com/juliano-monet/digital-twin-warehouse';
        break;
      case 'airport':
        bookingLink = 'https://calendly.com/juliano-monet/digital-twin-airport';
        break;
      case 'events':
        bookingLink = 'https://calendly.com/juliano-monet/digital-twin-events-venues';
        break;
      default:
        bookingLink = 'https://calendly.com/juliano-monet'; // Default link if no match
    }

    // Open the correct booking link in a new tab
    window.open(bookingLink, '_blank');
  };

  return (
    <Button
      className={`bg-white text-black ${size ? "px-2 py-3 text-xs" : "w-44 px-6 py-6 text-lg"} rounded-lg font-medium transition duration-1000 hover:text-white shadow-lg sm:w-auto relative overflow-hidden hover:animated-gradient-bg`}
      onClick={handleBookDemo}
    >
      Book a demo
      <ArrowRight className={`${size ? "h-2 w-2" : "ml-2 h-5 w-5"} arrow-icon`} />
    </Button>
  );
};

export default BookDemoButton;