
import { Button } from "@/components/ui/button";
import { ArrowRight, ChevronDown } from "lucide-react";
import { useState, useEffect, useRef } from "react";
import BookDemoButton from "../Props/BookDemo"
import VideoBg from "../Props/VideoBg";

const HeroSection = () => {
  const scrollToIndustries = () => {
    const industriesSection = document.getElementById('industries');
    if (industriesSection) {
      industriesSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Action verbs that will animate
  const actionVerbs = ["Create", "Communicate", "Visualize", "Plan"];
  const [currentVerbIndex, setCurrentVerbIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  
  // Stats counter for digitized space
  const [sqmDigitized, setSqmDigitized] = useState(0);
  const targetSqm = 200000;
  
  // Handle verb animation
  useEffect(() => {
    const interval = setInterval(() => {
      setIsAnimating(true);
      setTimeout(() => {
        setCurrentVerbIndex((prev) => (prev + 1) % actionVerbs.length);
        setIsAnimating(false);
      }, 500); // Half the interval for flip animation
    }, 3000);
    
    return () => clearInterval(interval);
  }, []);

  // Handle sqm counter animation
  useEffect(() => {
    // Initial animation to reach target
    const duration = 2000; // 2 seconds for animation
    const steps = 27; // 60 steps (30 frames per second for 2 seconds)
    const interval = duration / steps;
    const increment = targetSqm / steps;
    
    let count = 0;
    const initialCounter = setInterval(() => {
      count += 1;
      setSqmDigitized(Math.min(Math.floor(increment * count), targetSqm));
      
      if (count >= steps) {
        clearInterval(initialCounter);
      }
    }, interval);
    
    return () => clearInterval(initialCounter);
  }, []);

  return (
    <section className="min-h-full py-40 relative overflow-hidden flex flex-col items-center">
      <div className="absolute inset-0 z-0">
        <VideoBg source="/industryVideos/point cloud animation.mp4" styleClass="w-full h-full object-cover opacity-20" />
      </div>
      <div className="absolute inset-0 pointer-events-none">
        <div className="absolute top-0 left-[20%] w-[500px] h-[500px] bg-monet-blue/10 rounded-full filter blur-[120px]"></div>
        <div className="absolute bottom-0 right-[20%] w-[400px] h-[400px] bg-monet-purple/10 rounded-full filter blur-[100px]"></div>
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[600px] h-[600px] bg-gradient-radial from-monet-blue/5 to-transparent opacity-30"></div>
      </div>
      
      <div className="container mx-auto px84 relative z-10 flex-1">
        <div className="flex w-full justify-center flex-col lg:flex-row gap-5 items-center">
          {/* Left Column - Value Proposition */}
          <div className="flex flex-col text-center items-center lg:items-start justify-center lg:text-left w-full lg:w-7/12 animate-fade-in">
            <h1 className="font-display font-bold mb-6">
              <div className="flex text-6xl justify-center lg:justify-start items-center mb-2 h-16 overflow-hidden">
                <span className={`inline-block transition-all text-gradient duration-500 ${isAnimating ? 'opacity-0 transform translate-y-8' : 'opacity-100'}`}>
                  {actionVerbs[currentVerbIndex]}
                </span>
              </div>
              
              <span className="block text-3xl lg:text-4xl text-white/90">
               tasks in a Digital Twin
              </span>
            </h1>
            
            <p className="text-center lg:text-left text-white/70 text-lg max-w-lg mb-8">
             Streamline your operations and create immersive 3D experiences in minutes
            </p>
            
            <div className="flex flex-col sm:flex-row items-start gap-4">
              <BookDemoButton />
            </div>
            {/* Stats Counter Section */}
            <div className="flex flex-col items-center justify-center lg:items-start w-full mt-10">
              <div className="flex w-full md:w-2/3 opacity-80 items-center mb-2 justify-start">
                {/* Square Meters Digitized Counter - Animated on load */}
                <div className="w-1/2 text-center">
                  <div className="text-2xl font-display font-bold text-gray-200">
                    +{sqmDigitized.toLocaleString()} m²
                  </div>
                  <p className="text-white/70">spaces digitized</p>
                </div>
                <div  className="h-20 border-l border-white/10"></div>
                {/* ROI Metric - More Actionable */}
                <div className="w-1/2 text-center">
                  <div className="text-2xl font-display font-bold text-gray-200">
                    30% faster
                  </div>
                  <p className="text-white/70">project delivery</p>
                </div>
              </div>
              <div className="mt-8 w-2/3 text-center">
                <button onClick={scrollToIndustries} className="inline-flex items-center text-white/70 hover:text-white transition-colors">
                  <span className="mr-2">Discover Industry Solutions</span>
                  <ChevronDown className="w-5 h-5 animate-bounce" />
                </button>
              </div>
            </div>
          </div>
          
          {/* Right Column - Digital Twin Image */}
          <div className="w-full lg:w-5/12 animate-fade-in" style={{ animationDelay: '400ms' }}>
            <div className="glass-card rounded-xl overflow-hidden border border-white/10 shadow-xl relative">
              <img 
                src="/assets/Digital Twins.webp" 
                alt="Digital Twin Visualization" 
                className="w-full h-auto rounded-lg object-cover shadow-lg" 
              />
              <div className="absolute inset-0 bg-gradient-to-t from-monet-darker/80 to-transparent opacity-40 pointer-events-none"></div>
            </div>
          </div>
        </div>
      </div>


      <style>
        {`
          @keyframes float {
            0%, 100% { transform: translateY(0); }
            50% { transform: translateY(-10px); }
          }
          
          .hover-lift {
            transition: transform 0.3s ease, box-shadow 0.3s ease;
          }
          
          .hover-lift:hover {
            transform: translateY(-5px);
            box-shadow: 0 10px 25px -5px rgba(59, 130, 246, 0.3);
          }
        `}
      </style>
    </section>
  );
};

export default HeroSection;