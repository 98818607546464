
import { Palette, Building2, Warehouse, Plane, Sofa, Building } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { motion, AnimatePresence } from 'framer-motion';
import { useRouter } from 'next/router';
import VideoBg from '../Props/VideoBg';

const IndustrySection = () => {
  return (
    <section id="industries" className="py-20 px-8 relative">
      <div className="absolute inset-0 bg-gradient-radial from-monet-blue/10 via-transparent to-transparent opacity-30 pointer-events-none"></div>
      <div className="container mx-auto px-4">
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-6xl font-display font-bold mb-4 text-white">
            Reduce <span className="text-red-400">costs</span> and boost <span className="text-green-400">efficiency</span>
          </h2>
        </div>
        
        <div className="mx-auto">
          <Tabs defaultValue="events" className="w-full">
            <TabsList className="grid grid-cols-3 md:grid-cols-3 md:mb-16 lg:grid-cols-6 bg-black/20 backdrop-blur-md rounded-lg p-1 mb-16">
              <IndustryTab value="events" icon={<Building2 className="w-4 h-5 mr-2" />} label="Events" />
              <IndustryTab value="art" icon={<Palette className="w-4 h-5 mr-2" />} label="Art & Culture" />
              <IndustryTab value="warehouse" icon={<Warehouse className="w-4 h-5 mr-2" />} label="Warehousing" />
              <IndustryTab value="airports" icon={<Plane className="w-4 h-5 mr-2" />} label="Airports" />
              <IndustryTab value="interior" icon={<Sofa className="w-4 h-5 mr-2" />} label="Interior" />
              <IndustryTab value="realestate" icon={<Building className="w-4 h-5 mr-2" />} label="Real Estate" />
            </TabsList>
            
            <div className="bg-black/20 backdrop-blur-md rounded-xl p-6 border border-white/10 relative overflow-hidden min-h-[1000px] md:min-h-[500px]">
              <AnimatePresence mode="wait">
                <IndustryContent
                  value="events"
                  title="Events & Venues"
                  description="Transform event planning and execution with collaborative digital twins."
                  features={[
                    "Create digital venue twins in minutes using just a phone",
                    "Streamline operations with collaborative planning",
                    "Generate event concepts with our prompt-to-event feature",
                    "Annotate directly within the 3D environment",
                    "Extract technical drawings for production teams"
                  ]}
                  image="/industryVideos/Event Monet.webp"
                />
                <IndustryContent
                  value="art"
                  title="Art & Culture"
                  description="Revolutionize how art is experienced, curated, and preserved in the digital age."
                  features={[
                    "Create collaborative 3D curation environments",
                    "Visualize artworks in immersive 3D spaces",
                    "Showcase collections on websites in interactive 3D",
                    "Preserve cultural heritage sites digitally",
                    "Digitally reconstruct damaged or destroyed heritage sites"
                  ]}
                  image="/industryVideos/Art & Culture Monet.mp4"
                />
                <IndustryContent
                  value="warehouse"
                  title="Warehousing & Logistics"
                  description="Optimize warehouse layouts, monitor operations, and enhance efficiency."
                  features={[
                    "Layout optimization with digital twins",
                    "Integration with smart sensors for real-time monitoring",
                    "Data extraction and analytics for operational insights",
                    "Spatial data visualization in 3D environments",
                    "Annotate and collaborate on logistics planning"
                  ]}
                  image="/industryVideos/Warehouse Monet.mp4"
                />
              
                <IndustryContent
                  value="airports"
                  title="Airports & Transportation"
                  description="Enhance passenger experiences and optimize complex airport operations."
                  features={[
                    "Create comprehensive digital twins of entire airport facilities",
                    "Real-time monitoring of passenger flows and congestion",
                    "Spatial data analysis for operational optimization",
                    "Extract actionable insights for improved decision making",
                    "Collaborative planning for infrastructure changes"
                  ]}
                  image="/industryVideos/Airport Monet.mp4"
                />
              
                <IndustryContent
                  value="interior"
                  title="Interior Design"
                  description="Transform interior design with immersive visualization and collaboration."
                  features={[
                    "Create digital twins of interior spaces using just a phone",
                    "Visualize design changes in real-time",
                    "Collaborate with clients in a shared 3D environment",
                    "Annotate design elements directly in the model",
                    "Generate technical specifications and material lists"
                  ]}
                  image="/industryVideos/Interior Monet.mp4"
                />
              
                <IndustryContent
                  value="realestate"
                  title="Real Estate"
                  description="Revolutionize property marketing and visualization before financial commitment."
                  features={[
                    "Create photorealistic digital twins of properties",
                    "Offer immersive augmented reality property tours",
                    "Visualize renovation or construction options in 3D",
                    "Enable remote collaborative property viewings",
                    "Generate accurate floor plans and measurements"
                  ]}
                  image="/industryVideos/real-estate-monet.webp"
                />
              </AnimatePresence>
            </div>
          </Tabs>
        </div>
      </div>
    </section>
  );
};

interface IndustryTabProps {
  value: string;
  icon: React.ReactNode;
  label: string;
}

const IndustryTab = ({ value, icon, label }: IndustryTabProps) => {
  return (
    <TabsTrigger 
      value={value}
      className="data-[state=active]:glass-card data-[state=active]:text-white py-3"
    >
      <div className="flex items-center">
        {icon}
        <span className="hidden md:inline">{label}</span>
      </div>
    </TabsTrigger>
  );
};

interface IndustryContentProps {
  value: string;
  title: string;
  description: string;
  features: string[];
  image: string;
}

const IndustryContent = ({ value, title, description, features, image }: IndustryContentProps) => {
  const router = useRouter();

  return (
    <TabsContent value={value} className="absolute h-full inset-0">
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
        className="grid grid-cols-1 md:grid-cols-2 gap-8 flex items-center justify-center h-full"
      >
        <div className="order-2 md:order-1 p-3 py-5">
          <h3 className="text-3xl font-display font-bold mb-3 text-white">{title}</h3>
          <p className="text-white/70 mb-6">{description}</p>
          <ul className="space-y-3 mb-6">
            {features.map((feature, index) => (
              <motion.li 
                key={index} 
                className="flex items-start"
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: index * 0.1 }}
              >
                <div className="w-6 h-6 rounded-full bg-monet-blue/20 flex items-center justify-center mr-3 mt-0.5">
                  <span className="text-monet-blue text-sm">✓</span>
                </div>
                <span className="text-white/80">{feature}</span>
              </motion.li>
            ))}
          </ul>
          
          <Button onClick={() => router.push(`/Industry/${encodeURIComponent(value)}`)} className="bg-gradient-to-r from-monet-blue to-monet-purple hover:opacity-90 text-white">
            Learn more
          </Button>
        </div>
        
        <div className="order-1 md:order-2 px-3">
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className="relative rounded-lg overflow-hidden shadow-2xl"
          >
            <div className="absolute inset-0 bg-gradient-to-br from-monet-blue/30 to-monet-purple/30 mix-blend-overlay"></div>
            {image.endsWith('.mp4') ? 
              <VideoBg source={image} styleClass="w-full h-auto object-cover rounded-lg" />
             : 
              <img
                src={image}
                alt="Media content"
                className="w-full h-auto object-cover rounded-lg"
              />
            }
            <div className="absolute inset-0 border border-white/10 rounded-lg pointer-events-none"></div>
          </motion.div>
        </div>
      </motion.div>
    </TabsContent>
  );
};

export default IndustrySection;