
import { ReactNode } from 'react';

interface FeatureCardProps {
  icon: ReactNode;
  title: string;
  description: string;
  className?: string;
}

const FeatureCard = ({ icon, title, description, className = "" }: FeatureCardProps) => {
  return (
    <div className={`glass-card p-6 rounded-xl hover-lift group ${className}`}>
      <div className="flex flex-col space-y-4">
        <div className="w-12 h-12 flex items-center justify-center rounded-lg bg-gradient-to-br from-monet-blue/20 to-monet-purple/20 text-monet-blue mb-4">
          {icon}
        </div>
        <h3 className="text-xl font-display font-semibold text-white">{title}</h3>
        <p className="text-white/70 leading-relaxed">{description}</p>
      </div>
      <div className="absolute inset-0 rounded-xl bg-gradient-to-br from-monet-blue/10 to-monet-purple/10 opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none"></div>
    </div>
  );
};

export default FeatureCard;