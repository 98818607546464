import React from 'react';
import Head from 'next/head';
import Image from 'next/image';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebase/firebaseApp';
import Loader from '../components/Loader';
import IndustrySection  from '../components/Home/IndustrySection';
import ClientsSlider  from '../components/Home/ClientsSlider';
import HeroSection  from '../components/Home/HeroSection';
import FeatureCard  from '../components/Home/FeatureCard';
import CTASection  from '../components/Home/CTASection';

import { 
  Box, // Replaced 'Cube' with 'Box'
  LayoutGrid, 
  Users2, 
  BarChart3, 
  Smartphone, 
  FileText, 
  Bot,
  Building,
  PenTool
} from "lucide-react";


// interface ProcessStepProps {
//   number: string;
//   title: string;
//   description: string;
// }

const ProcessStep = ({ number, title, description }) => {
  return (
    <div className="relative z-10 flex flex-col items-center text-center">
      <div className="w-16 h-16 rounded-full bg-gradient-to-br from-monet-blue to-monet-purple flex items-center justify-center text-white font-bold text-xl font-display mb-6 relative">
        {number}
        <div className="absolute inset-0 rounded-full bg-gradient-to-br from-monet-blue to-monet-purple opacity-30 blur-md"></div>
      </div>
      
      <div className="glass-card p-6 rounded-xl w-full">
        <h3 className="text-xl font-display font-bold mb-3 text-white">{title}</h3>
        <p className="text-white/70">{description}</p>
      </div>
    </div>
  );
};

const Features = ({}) => {
  return (
    <section id="features" className="py-20 px-8 relative">
      <div className="absolute inset-0 bg-gradient-to-b from-monet-darker to-monet-dark/50 pointer-events-none"></div>
      <div className="absolute inset-0 grid-pattern opacity-10 pointer-events-none"></div>
      
      <div className="container mx-auto px-4 relative z-10">
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-display font-bold mb-4 text-white">
            Transform your world into <span className="text-gradient">intelligent</span> digital environments
          </h2>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-12">
          <FeatureCard 
            icon={<Bot className="w-6 h-6 text-monet-blue" />}
            title="AI Prompt to Event"
            description="Generate event concepts and layouts in minutes"
            className="md:col-span-1"
          />
          
          <FeatureCard 
            icon={<Users2 className="w-6 h-6 text-monet-blue" />}
            title="Collaborative Workspace"
            description="Work across teams in real-time within shared 3D environments. Annotate, plan, and visualize tasks"
          />
          
          <FeatureCard 
            icon={<Smartphone className="w-6 h-6 text-monet-blue" />}
            title="Image & Video to 3D"
            description="Transform photos and videos into 3D models with no specialized equipment needed"
          />

          <FeatureCard 
            icon={<LayoutGrid className="w-6 h-6 text-monet-blue" />}
            title="VIP Protocol & Simulations"
            description="Run simulations on your spaces for crowd management, VIP security protocols, and HSSE compliance checks"
          />
          
          <FeatureCard 
            icon={<Box className="w-6 h-6 text-monet-blue" />} 
            title="3D & AR / VR Visualization"
            description="Create immersive, interactive experiences for your website, presentations, or planning sessions" 
          />

          <FeatureCard 
            icon={<BarChart3 className="w-6 h-6 text-monet-blue" />}
            title="Spatial & Carbon Analytics"
            description="Unlock insights with AI-driven spatial analysis, heat maps, and carbon footprint tracking for smarter and greener decisions"
          />
        </div>
      </div>
    </section>
  )
}

const Howitworks = () => {
  return (
    <section id="how-it-works" className="py-20 relative">
      <div className="container mx-auto px-4">
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-display font-bold mb-4 text-white">
            How <span className="text-gradient">Monet</span> works
          </h2>
          <p className="text-white/70 max-w-2xl mx-auto text-lg">
            Creating and utilizing digital twins has never been easier
          </p>
        </div>
        
        <div className="max-w-5xl mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 relative">
            <div className="absolute top-12 left-[calc(50%-1px)] right-[calc(50%-1px)] h-[calc(100%-4rem)] bg-gradient-to-b from-monet-blue to-monet-purple opacity-20 hidden md:block"></div>
            
            <ProcessStep 
              number="01"
              title="Capture"
              description="Scan your space using just your smartphone camera. Walk around to capture all angles for a complete 3D model"
            />
            
            <ProcessStep 
              number="02"
              title="Integrate"
              description="Our AI technology automatically processes your 3D space into an interactive digital twin within minutes"
            />
            
            <ProcessStep 
              number="03"
              title="Utilize"
              description="Collaborate, plan, and create tasks from your digital twin across any device"
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default function OnBoardPage() {  
  const [user, loading] = useAuthState(auth);
  // if(loading) return <div className="h-screen pt-80"><Loader /></div>

  const Story = ({condition}) => {
    console.log("pppp",condition)
    return (
      <div className="">
        <div className='h-96 sticky'>
          <div className="fixed opacity-100 w-full max-h-64 h-2/3 top-0 z-0">
            <div className="relative w-[50rem] h-[36rem] md:w-[66rem] md:h-[56rem] lg:w-[110rem] lg:h-[46rem] ">
            </div>
          </div>
        </div>
        <div className="w-full">
          <div className="absolute bg-gradient-to-r from-white via-pink-500 to-white text-transparent bg-clip-text text-center z-20 font-bold w-full top-40 pb-5 text-8xl md:text-9xl">Monet</div>
          <div><div className="absolute text-center z-20 font-medium w-full top-80 pb-5 text-pink-100 text-lg md:text-xl px-5">Streamline events operations with an easy to use digital twin</div>
            <div className="bg-gradient-to-r from-pink-200 via-pink-400 to-pink-300 text-transparent pt-5 text-center bg-clip-text text-xl md:text-3xl">Website Update Coming soon</div>
          </div>
          {/* <Link href="https://apps.apple.com/us/app/the-monet/id6502591329" className="absolute w-full flex items-center justify-center z-20 pt-10 top-96">
            <div className="flex flex-row bg-pink-500 rounded-md text-black font-semibold py-2 px-5 items-center">
              <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} style={{ fill: "#fff", marginRight: '8px' }} viewBox="0 0 30 30">
                <path d="M25.565 9.785c-.123.077-3.051 1.702-3.051 5.305.138 4.109 3.695 5.55 3.756 5.55-.061.077-.537 1.963-1.947 3.94-1.119 1.703-2.361 3.42-4.247 3.42-1.794 0-2.438-1.135-4.508-1.135-2.223 0-2.852 1.135-4.554 1.135-1.886 0-3.22-1.809-4.4-3.496-1.533-2.208-2.836-5.673-2.882-9-.031-1.763.307-3.496 1.165-4.968 1.211-2.055 3.373-3.45 5.734-3.496 1.809-.061 3.419 1.242 4.523 1.242 1.058 0 3.036-1.242 5.274-1.242.966.001 3.542.292 5.137 2.745zM15.001 6.688c-.322-1.61.567-3.22 1.395-4.247 1.058-1.242 2.729-2.085 4.17-2.085a6.003 6.003 0 0 1-1.533 4.339c-.935 1.242-2.545 2.177-4.032 1.993z" />
              </svg>
              <div className="text-white">Download the App</div>
            </div>
          </Link> */}
        </div>
        <div className="mt-10 bg-black">
          <div className="flex space-x-5">
            <div className="w-full bg-black flex-col z-30 pb-7">
              <div className="w-full bg-black pb-20">
                <div className="relative px-10 w-full flex justify-center items-center">
                  <Image
                    unoptimized
                    src="/awards.png"
                    alt="Monet awards. Create virtual exhibition in minutes with Monet"
                    priority
                    width={1000}
                    height={600}
                    className="pt-14 sticky object-contain max-w-full h-auto"
                  />
                </div>          
              </div>
              {/* <h2 className="mx-7 text-white text-center font-bold text-2xl pb-10">Empowering the people behind your favortie events</h2> */}
            </div>
          </div>
          <div className="flex justify-center md:flex-row flex-col">
            <div className="bg-black pt-10 w-full flex z-30 flex-col">
              <div className="mx-12 pb-10">
                {/* <div className="opcity-80 pl-3 pt-2 text-white">Utilize Monet's technology to effortlessly digitize your objects and curate hyper realistic virtual exhibitions, providing viewers with immersive experiences that transcend physical limitations.</div> */}
              </div>
              {/* <div className="mx-12">
                <div className="bg-gradient-to-r from-blue-200 via-blue-400 to-blue-300 text-transparent bg-clip-text text-xl md:text-3xl">Talks, Tours, and Research</div>
                <div className="opacity-80 pl-3 pt-2 text-white">Maximize your collection's potential with live or on-demand talks and tours. By digitizing collections and revisiting past exhibitions, it reduces the risk of rare objects handling and expands educational outreach globally.</div>
              </div>
            </div>
          <div className="bg-black  pb-40 pt-10 w-full flex z-30 flex-col">
            <div className="mx-12 pb-10">
              <div className="bg-gradient-to-r from-green-200 via-green-400 to-green-300 text-transparent bg-clip-text text-xl md:text-3xl">Enhance Engagement</div>
              <div className="opacity-80 pl-3 pt-2 text-white">Our platform empowers artists, galleries, institutions, and collectors to showcase their collections across web, mobile, and Virtual Reality (VR) environments</div>
            </div>
            <div className="mx-12 pb-10">
              <div className="bg-gradient-to-r from-red-200 via-red-600 to-blue-300 text-transparent bg-clip-text text-xl md:text-3xl">Centralize your operations</div>
              <div className="opacity-80 pl-3 pt-2 text-white">Discover, acquire, and manage your digital collection with ease on a single platform. PayPal checkout provides a simple and secure experience, empowering the next wave of creative-tech</div>
            </div> */}
          </div>
        </div>
        </div>

      </div>
    )
  }
  return (
    <main>
      <Head>
        <title>Monet, making creativity accessible</title>
        <meta name="description" content="Streamline your operaitons with a digital twin you can set up in minutes."/>
      </Head>
      {/* {user ? <Story condition={true} /> : <Story condition={false} />} */}
      {/*user ? <Home user={user}/> : <Story />*/}
      <HeroSection />
      <ClientsSlider />
      <IndustrySection />
      <Features />
      <Howitworks />
      <CTASection />
    </main>
  );
}