import { useRef, useCallback, useEffect, useState } from "react";

interface VideoBackgroundProps {
  source: string;
  styleClass: string;
}

const VideoBg: React.FC<VideoBackgroundProps> = ({ source, styleClass }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [loading, setLoading] = useState(true); // Track loading state

  const loadVideoCb = useCallback(() => {
    if (videoRef.current) {
      const video = videoRef.current;
      video.controls = false;
      video.muted = true;
      video.autoplay = true;

      // Play the video with a promise handling for errors
      const playVideo = () => {
        const promise = video?.play();
        if (promise?.then) {
          promise.then(() => {
            setLoading(false); // Video successfully played
          }).catch((error) => {
            console.error('Autoplay failed: ', error);
          });
        }
      };

      // Trigger play after a slight delay
      setTimeout(() => {
        playVideo();
      }, 0);
    }
  }, []);

  useEffect(() => {
    loadVideoCb();
  }, [loadVideoCb]);

  return (
    <div className="relative w-full h-full">
      {loading && (
        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <span className="text-white text-lg animate-pulse">Loading...</span>
        </div>
      )}
      
      <video
        ref={videoRef}
        muted
        loop
        autoPlay
        playsInline
        preload="auto"
        className={`${styleClass} ${loading ? "opacity-0" : "opacity-100"} transition-opacity duration-500`}
        onLoadedData={() => setLoading(false)} // Video has loaded
        onWaiting={() => setLoading(true)} // Video is buffering
      >
        <source src={source} type="video/mp4" />
      </video>
    </div>
  );
};

export default VideoBg;