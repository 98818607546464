
import { Button } from "@/components/ui/button";
import BookDemoButton from "../Props/BookDemo"

const CTASection = ({type=""}) => {
  return (
    <section className="py-20 relative overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-b from-monet-darker/0 to-monet-purple/10 pointer-events-none"></div>
      <div className="absolute inset-0 grid-pattern opacity-20 pointer-events-none"></div>
      
      <div className="container mx-auto px-4 relative z-10">
        <div className="max-w-5xl mx-auto glass-card rounded-2xl p-10 md:p-16 border border-white/10 relative overflow-hidden">
          <div className="absolute top-0 left-0 w-full h-full bg-gradient-radial from-monet-blue/20 via-transparent to-transparent opacity-30"></div>
          
          <div className="relative z-10">            
            <h2 className="text-3xl md:text-5xl font-display font-bold text-center pb-6 animated-gradient-text">
              Revolutionize Your Digital Experience
            </h2>
            
            <p className="text-white/70 text-center max-w-2xl mx-auto mb-10 text-lg">
              Join the industry leaders who are already using Monet's digital twin technology to transform their spaces and enhance their operations.
            </p>
            
            <div className="flex flex-col sm:flex-row items-center justify-center gap-4">
              <div className="flex flex-col sm:flex-row items-start gap-4">
                <BookDemoButton type={type}/>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CTASection;